import { useState, useEffect, useMemo, lazy } from "react";
import debounce from "lodash/debounce";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";

import { isAccessible$, setPageTitle } from "@orangep/util";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useObservableState } from "observable-hooks";
import { createCustomer, listCustomers, updateCustomer } from "../helpers/api";

const FieldOrderingFilter = lazy(() =>
  import(
    /* webpackChunkName: "field-ordering-filter" */ "../components/filters/field-ordering-filter"
  ).then((module) => ({ default: module.FieldOrderingFilter }))
);
const OrderingTypeFilter = lazy(() =>
  import(
    /* webpackChunkName: "ordering-type-filter" */ "../components/filters/ordering-type-filter"
  ).then((module) => ({ default: module.OrderingTypeFilter }))
);
const FilterBar = lazy(() =>
  import(
    /* webpackChunkName: "filter-bar" */ "../components/filters/filter-bar"
  ).then((module) => ({ default: module.FilterBar }))
);
const CustomerCard = lazy(
  () =>
    import(
      /* webpackChunkName: "customer-card" */ "../components/customer-card"
    )
);
// import { CloseButton } from "../components/buttons";
const CloseButton = lazy(() =>
  import(/* webpackChunkName: "buttons" */ "../components/buttons").then(
    (module) => ({ default: module.CloseButton })
  )
);
// import { OrangeTextField } from "../components/mui-theme";
const OrangeTextField = lazy(() =>
  import(/* webpackChunkName: "mui-theme" */ "../components/mui-theme").then(
    (module) => ({ default: module.OrangeTextField })
  )
);
import { EnumType } from "json-to-graphql-query";

import { OmitCustomer } from "../helpers/types";
type FilterStatus = "filter-bar" | "filter-box" | "customer-create-box";

const CustomerListing = ({ singleSpa }) => {
  const isReadable = useObservableState(
    isAccessible$(["MAIN_MASTER", "MAIN_VIEW", "CUSTOMER_VIEW"]),
    false
  );

  const isWRitable = useObservableState(
    isAccessible$(["MAIN_MASTER", "CUSTOMER_MASTER"]),
    false
  );

  const [filterVisible, setFilterVisible] =
    useState<FilterStatus>("filter-bar");
  // const [offset, setOffset] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [orderField, setOrderField] = useState<OrderingField>("code");
  const [ordering, setOrdering] = useState<Ordering>("asc");

  setPageTitle("ข้อมูลลูกค้า");

  const ROWS_PER_PAGE = 5;
  const { data, fetchNextPage, hasNextPage, isFetching, refetch } =
    useInfiniteQuery({
      queryKey: [
        "customers",
        searchText
          ? {
              OR: [
                {
                  code: {
                    contains: searchText,
                    mode: new EnumType("insensitive"),
                  },
                },
                {
                  name: {
                    contains: searchText,
                    mode: new EnumType("insensitive"),
                  },
                },
              ],
            }
          : undefined,
        { [orderField]: ordering },
        // offset,
        ROWS_PER_PAGE,
      ],
      queryFn: listCustomers,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.customers.length < ROWS_PER_PAGE) return undefined;
        return pages.length * ROWS_PER_PAGE;
      },
      enabled: isReadable,
      refetchOnWindowFocus: false,
    });

  const updateMutation = useMutation({
    mutationFn: updateCustomer,
    onSuccess: () => {
      setFilterVisible("filter-bar");
      refetch();
    },
  });

  const createMutation = useMutation({
    mutationFn: createCustomer,
    onSuccess: () => {
      setFilterVisible("filter-bar");
      refetch();
    },
  });

  const handleSaveCustomer = (customer: OmitCustomer) => {
    const data = {
      name: customer.name,
      code: customer.code,
      address: customer.address,
      phone: customer.phone,
    };
    // update
    if (customer.id) {
      return updateMutation.mutate({
        id: customer.id,
        data,
      });
    }
    // create
    createMutation.mutate(data);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((e) => {
        setSearchText(e.target.value);
      }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  if (!isReadable) return null;

  const customers: OmitCustomer[] =
    data?.pages.map((p) => p.data.customers).flat() || [];

  return (
    <InfiniteScroll
      loadMore={() => {
        fetchNextPage();
      }}
      loader={
        <Box
          key={0}
          data-testid="infinite-scroll-loader"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <CircularProgress />
        </Box>
      }
      hasMore={hasNextPage}
    >
      <Grid container>
        <FilterBar
          visible={filterVisible === "filter-bar"}
          enableAddCustomer={isWRitable}
          onFilterClick={() => {
            setFilterVisible((prev) => "filter-box");
          }}
          onAddCustomerClick={() => {
            setFilterVisible((prev) => "customer-create-box");
          }}
        />
        <Grid item xs={12} p={filterVisible === "filter-box" ? 1 : null}>
          <Collapse in={filterVisible === "filter-box"}>
            <Paper sx={{ p: 2 }}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} md="auto">
                  <Typography px={1}>ค้นหา</Typography>
                </Grid>
                <Grid item xs={8} md="auto">
                  <OrangeTextField
                    size="small"
                    onChange={debouncedSearch}
                    placeholder="รหัส/ชื่อลูกค้า"
                  />
                </Grid>
                <Grid item xs={4} md="auto">
                  <Typography px={1}>เรียงลำดับ</Typography>
                </Grid>
                <Grid item xs={8} md="auto">
                  <FieldOrderingFilter
                    selected={orderField}
                    onChange={(value) => setOrderField(value)}
                  />
                </Grid>
                <Grid item xs={4} md="auto">
                  <Typography px={1}>จาก</Typography>
                </Grid>
                <Grid item xs={8} md="auto">
                  <OrderingTypeFilter
                    selected={ordering}
                    onChange={(value) => setOrdering(value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md="auto"
                  container
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <CloseButton
                      onClick={() => {
                        setFilterVisible((prev) => "filter-bar");
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        </Grid>

        <Grid item xs={12} p={filterVisible === "filter-box" ? 1 : null}>
          <Collapse in={filterVisible === "customer-create-box"}>
            <CustomerCard
              customer={undefined}
              startEditing={true}
              onSave={handleSaveCustomer}
              onClose={() => {
                setFilterVisible((prev) => "filter-bar");
              }}
              editable={isWRitable}
            />
          </Collapse>
        </Grid>
        {customers.map((customer, i) => (
          <Grid item xs={12} md={6} key={customer.id}>
            <CustomerCard
              customer={customer}
              editable={isWRitable}
              onSave={handleSaveCustomer}
            />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

export default CustomerListing;
