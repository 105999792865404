import { Routes, Route } from "react-router-dom";
import CustomerListing from "../pages/listing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "./mui-theme/mui-theme";

const queryClient = new QueryClient();

const AppRoutes = ({ singleSpa }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="customer">
            <Route
              path=""
              element={<CustomerListing singleSpa={singleSpa} />}
            />
            {/* <Route path="*" element={<Route404 />} /> */}
          </Route>
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default AppRoutes;
