import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./components/app-routes";

export default function Root(props) {
  return (
    <section style={{ fontFamily: "Kanit" }}>
      <Router>
        <AppRoutes singleSpa={props.singleSpa} />
      </Router>
    </section>
  );
}
