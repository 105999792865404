import { getAxiosInstance } from "@orangep/util";
import { jsonToGraphQLQuery, EnumType } from "json-to-graphql-query";
import { QueryFunctionContext } from "@tanstack/react-query";

type CustomerMutationData = {
  code: string;
  name: string;
  phone: string;
  address: string;
};

type updateCustomerData = {
  id: string;
  data: CustomerMutationData;
};

const customerFields = {
  id: true,
  code: true,
  name: true,
  phone: true,
  address: true,
  createdAt: true,
  updatedAt: true,
};

export function listCustomers({
  queryKey: [_, where = null, orderBy = null, take = 20],
  pageParam,
}: QueryFunctionContext) {
  const axiosInstance = getAxiosInstance();

  const order_by: any = orderBy;

  let orderByField: any = {};
  if (order_by.code) {
    orderByField = { code: new EnumType(order_by.code) };
  }

  if (order_by.name) {
    orderByField = { name: new EnumType(order_by.name) };
  }

  const query = jsonToGraphQLQuery(
    {
      query: {
        customers: {
          __args: {
            where,
            orderBy: orderByField,
            skip: pageParam || 0,
            take,
          },
          ...customerFields,
        },
      },
    },
    { includeFalsyKeys: false }
  );

  return axiosInstance
    .request({
      data: {
        query,
      },
    })
    .then((response) => response.data);
}

export function updateCustomer({ id, data }: updateCustomerData) {
  const axiosInstance = getAxiosInstance();

  const updateData = {
    code: { set: data.code },
    name: { set: data.name },
    phone: { set: data.phone },
    address: { set: data.address },
  };

  const query = jsonToGraphQLQuery(
    {
      mutation: {
        updateCustomer: {
          __args: {
            where: { id },
            data: updateData,
          },
          ...customerFields,
        },
      },
    },
    { includeFalsyKeys: true }
  );

  return axiosInstance
    .request({
      data: {
        query,
      },
    })
    .then((response) => response.data);
}

export function createCustomer(data: CustomerMutationData) {
  const axiosInstance = getAxiosInstance();

  const query = jsonToGraphQLQuery(
    {
      mutation: {
        createCustomer: {
          __args: {
            data,
          },
          ...customerFields,
        },
      },
    },
    { includeFalsyKeys: true }
  );

  return axiosInstance
    .request({
      data: {
        query,
      },
    })
    .then((response) => response.data);
}
